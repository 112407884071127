// Import all js dependencies.
import './src/css/layout.css'
import './src/css/responsive.css'
import './src/css/projects.css'
import './src/css/contact.css'
import './src/css/header.css'
import './src/css/offer.css'
import './src/css/bootstrap.min.css'
import './src/css/bootstrap.min.js'



import 'jquery/dist/jquery.min.js'
import 'popper.js/dist/popper.min'
import 'bootstrap/js/dist/util'
import 'bootstrap/js/dist/carousel'
import 'bootstrap/js/dist/dropdown'

